<template>
    <v-row justify="center">
        <v-alert v-if="error" type="error" outlined>{{ error }}</v-alert>
        <PopupDialog :dialogOpen="dialog"/>
        <SnackBar :snackbarOpen="snackbar" :text="snackbarText" @snackBarClosed="snackbar=false"/>
        <PopupDialog
            :dialogOpen="deleteDialog"
            :dialogText="$t('profile.delete.text')"
            :dialogTitle="$t('profile.delete.label')"
            dialogType="info"
            :askForPassword="true"
            @dialogOk="deleteProfile"
            @dialogCancel="deleteDialog=false"
        />
        <v-dialog
        v-model="changeDialog"
        width="500"
        persistent
        >
            <v-card>
                <v-card-title primary-title>
                    <span class="display-1 primary--text">{{ $t('profile.changePassword.label') }}</span>
                </v-card-title>

                <v-card-text class="subtitle-1">
                {{ $t('profile.changePassword.text') }}
                <v-form ref="changePasswordForm">
                    <InputField
                    :label="$t('profile.changePassword.oldPassword')"
                    icon="lock"
                    v-model="passwordChangeData.oldPassword"
                    rules="minLength"
                    inputType="password"
                    :class="[$vuetify.breakpoint.mdAndDown ? 'mx-1' : 'mx-10', 'mt-3']"
                    :inputValue="passwordChangeData.oldPassword"
                    />
                    <InputField
                    :label="$t('profile.changePassword.newPassword')"
                    icon="lock"
                    v-model="passwordChangeData.newPassword"
                    rules="minLength"
                    inputType="password"
                    :class="[$vuetify.breakpoint.mdAndDown ? 'mx-1' : 'mx-10']"
                    :inputValue="passwordChangeData.newPassword"
                    />
                    <InputField
                    :label="$t('profile.changePassword.repeatPassword')"
                        icon="lock"
                        v-model="passwordChangeData.newPasswordRepeat"
                        rules="samePass"
                        inputType="password"
                        :inputRulesData="passwordChangeData.newPassword"
                        :class="[$vuetify.breakpoint.mdAndDown ? 'mx-1' : 'mx-10']"
                        :inputValue="passwordChangeData.newPasswordRepeat"
                    />
                </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="changeDialog = false">{{ $t("profile.changePassword.actions.cancel") }}</v-btn>
                    <v-btn color="primary" text @click="changePassword()">{{ $t("profile.changePassword.actions.confirm") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
        v-model="changeEmailDialog"
        width="500"
        persistent
        >
            <v-card>
                <v-card-title primary-title>
                    <span class="display-1 primary--text">{{ $t("profile.changeEmail.label") }}</span>
                </v-card-title>

                <v-card-text class="subtitle-1">
                {{ $t("profile.changeEmail.text") }}
                <v-form ref="changeEmailForm">
                    <InputField
                    :label="$t('profile.changeEmail.password')"
                    icon="lock"
                    v-model="emailChangeData.password"
                    rules="minLength"
                    inputType="password"
                    :class="[$vuetify.breakpoint.mdAndDown ? 'mx-1' : 'mx-10', 'mt-3']"
                    :inputValue="emailChangeData.password"
                    />
                    <InputField
                    :label="$t('profile.changeEmail.newEmail')"
                    icon="email"
                    v-model="emailChangeData.newEmail"
                    rules="emailTest"
                    inputType="email"
                    :class="[$vuetify.breakpoint.mdAndDown ? 'mx-1' : 'mx-10']"
                    :inputValue="emailChangeData.newEmail"
                    />
                </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="changeEmailDialog = false">{{ $t("profile.changeEmail.actions.cancel") }}</v-btn>
                    <v-btn color="primary" text @click="changeEmail()">{{ $t("profile.changeEmail.actions.confirm") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-col cols=12 md=12>
            <v-skeleton-loader :loading="loading" type="card-avatar" transition="fade-transition">
                <v-card class="py-10 px-5 mx-3" flat style="border-radius: 20px">
                    <div v-if="isOwner">
                        <v-row justify="start">
                            <v-col cols=12 md=auto class="text-center mx-5" align-self="center">
                                <v-form ref="photoForm">
                                    <v-file-input
                                        :rules="inputRules['profilePhotoTest']"
                                        v-if="editing"
                                        v-model="fileProfile"
                                        prepend-icon="camera_alt"
                                        :label="$t('profile.info.photo')"
                                        accept="image/png, image/jpeg, image/bmp, image/jpg, image/JPG, image/JPEG, image/tiff, image/tif, image/gif">
                                    </v-file-input>
                                </v-form>
                                <ProfilePhoto :src="user.photo" heightImage="200" />
                            </v-col>
                            <v-col cols=12 md=4 class="pa-5">
                                <v-form ref="changeForm">
                                <div v-if="user.flag == '2'">
                                        <EditableLabel
                                            textClass="display-1 primary--text mb-2"
                                            :editing="editing"
                                            :label="$t('profile.info.user.name')"
                                            rules="maxLength64"
                                            :text="user.name"
                                            v-model="userChangeData.name"
                                        />
                                        <EditableLabel
                                            textClass="subtitle-1 grey--text text--darken-1"
                                            :editing="editing"
                                            :label="$t('profile.info.user.region')"
                                            :caption="$t('profile.info.user.region')"
                                            rules="required"
                                            :text="findRegionNameByCode(user.region)"
                                            v-model="userChangeData.region"
                                            inputType="regionAutocomplete"
                                        />

                                        <div class="caption grey--text text--darken-1">{{ $t('profile.info.user.username') }}</div>
                                        <div class="subtitle-1 grey--text text--darken-1">{{ user.username }}</div>

                                        <div class="caption grey--text text--darken-1">{{ $t('profile.info.user.email') }}</div>
                                        <div class="subtitle-1 grey--text text--darken-1">{{ user.email }}</div>
                                        <EditableLabel
                                            :editing="editing"
                                            :text="user.tags? Array.from(user.tags) : []"
                                            v-model="userChangeData.tags"
                                            inputType="tags"/>
                                </div>
                                <div v-else-if="user.flag == '1'">
                                        <EditableLabel
                                            textClass="display-1 primary--text mb-2"
                                            :editing="editing"
                                            :label="$t('profile.info.organisation.name')"
                                            rules="maxLength64"
                                            :text="user.title"
                                            v-model="userChangeData.title"
                                        />

                                        <div class="caption grey--text text--darken-1">{{ $t('profile.info.organisation.username') }}</div>
                                        <div class="subtitle-1 grey--text text--darken-1">{{ user.username }}</div>

                                        <div class="caption grey--text text--darken-1">{{ $t('profile.info.organisation.email') }}</div>
                                        <div class="subtitle-1 grey--text text--darken-1">{{ user.email }}</div>

                                        <EditableLabel
                                            textClass="subtitle-1 grey--text text--darken-1"
                                            :editing="editing"
                                            :label="$t('profile.info.organisation.phone')"
                                            :caption="$t('profile.info.organisation.phone')"
                                            rules="phoneTest"
                                            :text="user.telephone ? user.telephone : '-'"
                                            :inputValue="user.telephone ? user.telephone : ''"
                                            v-model="userChangeData.telephone"
                                        />

                                        <EditableLabel
                                            textClass="subtitle-1 grey--text text--darken-1"
                                            :editing="editing"
                                            :label="$t('profile.info.organisation.address')"
                                            :caption="$t('profile.info.organisation.address')"
                                            rules="maxLength100"
                                            :text="user.address"
                                            @input="(value) => {userChangeData.address = value.name}"
                                            inputType="address"
                                        />

                                </div>
                                </v-form>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols=12 md=auto class="pa-5 d-flex flex-column justify-space-between align-start">
                              <div class="d-flex flex-column align-start">
                                <v-btn text class="grey--text text--darken-2 text-none" @click="changeEditing">
                                    <div v-if="!editing">
                                        <v-icon class="primary--text mr-2">edit</v-icon>
                                        <span>{{ $t("profile.edit.editProfile") }}</span>
                                    </div>
                                    <div v-else>
                                        <v-icon class="primary--text mr-2">save_alt</v-icon>
                                        <span>{{ $t("profile.edit.save") }}</span>
                                    </div>
                                </v-btn>
                                <v-btn text class="grey--text text--darken-2 text-none" @click="cancelEditing" v-if="editing">
                                    <v-icon class="primary--text mr-2">cancel</v-icon>
                                    <span>{{ $t("profile.edit.cancel") }}</span>
                                </v-btn>
                                <v-btn text class="grey--text text--darken-2 text-none" @click="changeEmailDialogFunc" v-if="!editing">
                                    <v-icon class="primary--text mr-2">email</v-icon>
                                    <span>{{ $t("profile.edit.email") }}</span>
                                </v-btn>
                                <v-btn text class="grey--text text--darken-2 text-none" @click="changePasswordDialogFunc" v-if="!editing">
                                    <v-icon class="primary--text mr-2">lock</v-icon>
                                    <span>{{ $t("profile.edit.password") }}</span>
                                </v-btn>
                              </div>
                                <v-btn text class="grey--text text--darken-2 text-none" @click="deleteProfileDialog" v-if="!editing">
                                    <v-icon class="primary--text mr-2">delete</v-icon>
                                    <span>{{ $t("profile.edit.delete") }}</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols=12 md=8>
                                <EditableLabel
                                    :style="$vuetify.breakpoint.smAndDown ? 'line-height: 1.7rem; white-space: pre-wrap;' : 'line-height: 2.2rem; white-space: pre-wrap;'"
                                    :textClass="[$vuetify.breakpoint.smAndDown ? 'body-2' : 'title', 'font-weight-regular', 'grey--text', 'text--darken-1', 'text-justify']"
                                    :editing="editing"
                                    :label="$t('profile.info.description')"
                                    rules="required"
                                    :text="user.desc"
                                    v-model="userChangeData.desc"
                                    inputType="textarea"
                                />
                            </v-col>
                        </v-row>
                        <v-checkbox v-if="editing" v-model="subscriber" @change="subscriberChange" :label="$t('profile.unsubscribe')" hide-details></v-checkbox>
                    </div>
                    <div v-else>
                        <v-row justify=center>
                            <v-col cols=auto class="pa-0 pb-5 mr-3" align-self="center">
                                <v-icon x-large>{{ user.flag==1 ? 'business' : 'person' }}</v-icon>
                            </v-col>
                            <v-col cols=auto class="pa-0 pb-5" align-self="center">
                                <span class="grey--text text--darken-2 display-1">
                                    {{ user.flag==1 ? $t("profile.type.organization") : $t("profile.type.volunteer") }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-3 mb-5"></v-divider>
                        <v-row justify="start">
                        <v-col cols=12 md=auto class="text-center mx-5" align-self="center">
                            <v-file-input v-if="editing" v-model="fileProfile" prepend-icon="camera_alt" :label="$t('profile.info.photo')" accept="image/png, image/jpeg, image/bmp, image/jpg, image/JPG, image/JPEG, image/tiff, image/tif, image/gif"></v-file-input>
                            <ProfilePhoto :src="user.photo" heightImage="200" />
                        </v-col>
                        <v-col cols=12 md=4 class="pa-5">
                            <div v-if="user.flag == '2'">
                                <v-form ref="changeForm">
                                    <div class="display-1 primary--text mb-2">{{ user.name }}</div>
                                    <div v-if="user.email">
                                        <div class="caption grey--text text--darken-1">{{ $t("profile.info.user.email") }}</div>
                                        <div class="subtitle-1 grey--text text--darken-1">{{ user.email }}</div>
                                    </div>

                                    <Tag v-for="(item, index) in user.tags" :key="index" :name="item" :tooltip="true"/>
                                </v-form>
                            </div>
                            <div v-else-if="user.flag == '1'">
                                <div class="display-1 primary--text mb-2">{{ user.title }}</div>

                                <div class="caption grey--text text--darken-1">{{ $t("profile.info.organisation.email") }}</div>
                                <div class="subtitle-1 grey--text text--darken-1">{{ user.email }}</div>

                                <div v-if="user.telephone" class="caption grey--text text--darken-1">{{ $t("profile.info.organisation.phone") }}</div>
                                <div v-if="user.telephone" class="subtitle-1 grey--text text--darken-1">{{ user.telephone }}</div>

                                <div class="subtitle-1 grey--text text--darken-1">{{ user.address }}</div>
                            </div>
                        </v-col>
                        <v-spacer></v-spacer>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols=12 md=8 class="text-center">
                                <div :style="$vuetify.breakpoint.smAndDown ? 'line-height: 1.7rem; white-space: pre-wrap;' : 'line-height: 2.2rem; white-space: pre-wrap;'"
                                :class="[$vuetify.breakpoint.smAndDown ? 'body-2' : 'title', 'font-weight-regular', 'grey--text', 'text--darken-1', 'text-justify']" >
                                    {{ user.desc }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>

            </v-skeleton-loader>
        </v-col>
    </v-row>
</template>

<script>
    import PopupDialog from '@/components/partials/PopupDialog'
    import ProfilePhoto from '@/components/partials/ProfilePhoto'
    import EditableLabel from '@/components/partials/EditableLabel'
    import utilitiesMixin from '@/mixins/utilitiesMixin.js'
    import inputRulesMixin from '@/mixins/inputRulesMixin.js'
    import InputField from '@/components/partials/InputField'
    import SnackBar from '@/components/partials/SnackBar'
    import Tag from '@/components/partials/Tag'
    import axios from 'axios'
    export default {
        name: "WebAppProfile",
        components: {ProfilePhoto, EditableLabel, Tag, PopupDialog, InputField, SnackBar},
        mixins: [utilitiesMixin, inputRulesMixin],
        data() {
            return {
                deleteDialog: false,
                dialog: false,
                error: '',
                user: {},
                userChangeData: {},
                loading: true,
                loaded: 0,
                fileProfile: {},
                editing: false,
                passwordChangeData: {
                    oldPassword: '',
                    newPassword: '',
                    newPasswordRepeat: ''
                },
                changeDialog: false,
                emailChangeData: {
                    password: '',
                    newEmail: ''
                },
                changeEmailDialog: false,
                snackbar: false,
                snackbarText: '',
                subscriber: true
            }
        },
        methods: {
            getOzInfo() {
                axios.post('/get_oz_info', {ID_OZ: parseInt(this.$route.params.id)})
                .then(response => {
                    this.error = ''
                    this.user = response.data
                    this.load(2)
                })
                .catch(error => {
                    if (error.response && (error.response.status == 400 || error.response.status == 403)) {
                        this.error = this.$t("profile.messages.notLoggedIn")
                        window.scrollTo(0,0)
                    } else {
                        this.dialog = true
                    }
                })
            },
            getUserData() {
                if (this.isOwner) {
                    axios.post('/auth/user')
                    .then(response => {
                        this.error = ''
                        this.user = response.data
                        this.subscriber = !response.data.subscriber
                        this.load(1)
                    })
                    .catch(error => {
                        if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                            this.$router.push({ name: 'logout' })
                        } else {
                            this.dialog = true
                        }
                    })
                } else {
                    axios.post('/auth/user', {ID_user: parseInt(this.$route.params.id)})
                    .then(response => {
                        this.error = ''
                        this.user = response.data
                        this.load(2)
                    })
                    .catch(error => {
                        if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                            this.$router.push({ name: 'logout' })
                        } else if (error.response && error.response.data && error.response.data.message) {
                            if (error.response.data.message === "User not found") {
                                this.error = this.$t("profile.messages.notFound")
                                window.scrollTo(0,0)
                            }
                        } else {
                            this.dialog = true
                        }
                    })
                }
            },
            setProfilePhoto() {
                if (this.$refs.photoForm.validate() && this.fileProfile.name) {
                    let formData = new FormData();
                    formData.append('file', this.fileProfile);
                    axios.post('/auth/upload',
                        formData, {
                            headers: {
                            'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(
                        response => {
                            this.error = ''
                            if (response.data.message === 'success') {
                                axios.post('/auth/set_profile_photo', {filename: response.data.filename})
                                .then(() => {
                                    this.getUserData()
                                })
                                .catch(error => {
                                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                                        this.$router.push({ name: 'logout' })
                                    } else if (error.response && error.response.data && error.response.data.message && error.response.data.message.includes('specified')) {
                                        this.error = this.$t("profile.messages.photoInvalidPicture")
                                        window.scrollTo(0,0)
                                    } else {
                                        this.dialog = true
                                    }
                                })
                            }
                        }
                    ).catch(error => {
                        if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                            this.$router.push({ name: 'logout' })
                        } else if (error.response && error.response.data && error.response.data.message && (error.response.data.message.includes('INVALID DATA') || error.response.data.message == 'ERROR: No files were uploaded')) {
                            this.error = this.$t("profile.messages.photoInvalidFile")
                            window.scrollTo(0,0)
                        } else if (error.isAxiosError) {
                            this.error = this.$t("profile.messages.photoFileTooBig")
                            window.scrollTo(0,0)
                        } else {
                            this.dialog = true
                        }
                    })
                }
            },
            setUserInfo() {
                let toChange = this.processChangeData(this.userChangeData, this.user)
                if (Object.keys(toChange).length > 0) {
                    axios.post('/auth/change_user_info', toChange)
                    .then(response => {
                        this.error = ''
                        if (response.data.message == 'User info changed successfully') {
                            this.getUserData()
                        } else {
                            this.dialog = true
                        }
                    }).catch(error => {
                        if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                            this.$router.push({ name: 'logout' })
                        } else if (error.response && error.response.data && error.response.data.message) {
                            const msg =  error.response.data.message
                            this.loading = false
                            if (msg.includes('INVALID DATA')) {
                                this.error = this.$t("formError")
                                window.scrollTo(0,0)
                            } else {
                                this.dialog = true
                            }
                        } else {
                            this.dialog = true
                        }
                    })
                }
            },
            changeEditing() {
                if (!this.editing) {
                    this.editing = true
                    this.fileProfile = {}
                    this.userChangeData.username = this.user.username
                    this.userChangeData.email = this.user.email
                    this.userChangeData.desc = this.user.desc
                    this.userChangeData.tags = this.user.tags
                    if (this.$store.state.loggedInFlag == '1') {
                        this.userChangeData.title = this.user.title
                        this.userChangeData.telephone = this.user.telephone
                        this.userChangeData.address = this.user.address
                    }
                    else if (this.$store.state.loggedInFlag == '2') {
                        //this.userChangeData.birth = this.formatDateBack(this.user.birth)
                        this.userChangeData.name = this.user.name
                    }
                }
                else {
                    if (this.$refs.changeForm.validate() && this.$refs.photoForm.validate()) {
                        this.editing = false
                        this.setProfilePhoto()
                        this.setUserInfo()
                    }
                }
            },
            cancelEditing() {
                this.editing = false
                this.userChangeData = {}
            },
            processChangeData(changeData, previousData) {
                let toChange = {}
                Object.keys(changeData).forEach(
                    key => {
                        let data = previousData[key]
                        let changedData = changeData[key]
                        if (key == 'region') {
                            changedData = this.$store.state.regions[changedData].code
                        }
                        if (key == 'telephone') {
                            if (changedData == null || changedData == '') {
                                changedData = null
                            } else {
                                changedData = changedData.replace(/\s/g, '')
                            }
                        }
                        if (data != changedData) {
                            toChange[key] = changedData
                        }
                    }
                )
                return toChange
            },
            load(amount) {
                this.loaded += amount
                let loadAmount = 2
                if (this.$store.state.loggedInFlag == '1') {
                    loadAmount = 1
                }
                if (this.loaded >= loadAmount) {
                    this.loading = false
                }
            },
            deleteProfileDialog () {
                this.deleteDialog = true
            },
            changePasswordDialogFunc () {
                this.changeDialog = true
                this.passwordChangeData.oldPassword = ''
                this.passwordChangeData.newPassword = ''
                this.passwordChangeData.newPasswordRepeat = ''
            },
            changeEmailDialogFunc () {
                this.changeEmailDialog = true
                this.emailChangeData.password = ''
                this.emailChangeData.newEmail = ''
            },
            deleteProfile (password) {
                this.deleteDialog = false
                axios.post('/auth/delete_account_email', {password: password})
                .then(() => {
                    this.error = ''
                    this.$router.push({name: 'logout'})
                })
                .catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        if (error.response && error.response.data && error.response.data.message) {
                            const msg =  error.response.data.message
                            if (msg.includes('password')) {
                                this.error = this.$t("profile.messages.wrongPassword")
                                window.scrollTo(0,0)
                            } else {
                                this.dialog = true
                            }
                        } else {
                            this.dialog = true
                        }
                    }
                })
            },
            changePassword () {
                if (this.$refs.changePasswordForm.validate()) {
                    this.changeDialog = false
                    this.error = ''
                    axios.post('/auth/change_password', {password: this.passwordChangeData.oldPassword, newPassword: this.passwordChangeData.newPassword})
                    .then(
                        () => {
                            this.snackbarText = this.$t("profile.messages.passwordChanged")
                            this.snackbar = true
                        }
                    )
                    .catch(error => {
                        if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                            this.error = ''
                            this.$router.push({ name: 'logout' })
                        } else {
                            if (error.response && error.response.data && error.response.data.message) {
                                const msg =  error.response.data.message
                                if (msg == "Password is wrong") {
                                this.error = this.$t("profile.messages.wrongPassword")
                                    window.scrollTo(0,0)
                                } else {
                                    this.dialog = true
                                }
                            } else {
                                this.dialog = true
                            }
                        }
                    })
                }
            },
            changeEmail() {
                if (this.$refs.changeEmailForm.validate()) {
                    this.changeEmailDialog = false
                    this.error = ''
                    axios.post('/auth/change_email', {password: this.emailChangeData.password, email: this.emailChangeData.newEmail})
                    .then(
                        () => {
                            this.snackbarText = this.$t("profile.messages.emailChangeSent")
                            this.snackbar = true
                        }
                    )
                    .catch(error => {
                        if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                            this.error = ''
                            this.$router.push({ name: 'logout' })
                        } else {
                            if (error.response && error.response.data && error.response.data.message) {
                                const msg =  error.response.data.message
                                if (msg == "Password is wrong") {
                                    this.error = this.$t("profile.messages.wrongPassword")
                                    window.scrollTo(0,0)
                                } else {
                                    this.dialog = true
                                }
                            } else {
                                this.dialog = true
                            }
                        }
                    })
                }
            },
            subscriberChange() {
                axios.post('/auth/mail_subscription', {subscribe: this.subscriber ? 0 : 1})
                .then(() => {
                    this.snackbarText = this.$t("profile.messages.subscriptionChanged")
                    this.snackbar = true
                })
                .catch(() => {
                    this.dialog = true
                })
                }
        },
        computed: {
            isOwner: function () {
                return this.$route.params.id == this.$store.state.loggedInId
            },
            envMode: function () {
                return process.env.NODE_ENV
            }
        },
        watch: {
            '$route.params': {
                handler() {
                    if (this.$store.getters.loggedIn) {
                        this.getUserData()
                    }
                },
                immediate: true,
            }
        },
        created() {
            if (this.$store.getters.loggedIn) {
                this.getUserData()
                if (this.isOwner) {
                    document.title = this.$t("profile.title")
                }
            } else {
                this.getOzInfo()
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
