<template>
  <div>
    <div class="caption grey--text text--darken-1" v-if="caption && (inputType != 'address')">
      {{ caption }}
    </div>

    <div v-if="!editing">
      <span v-if="inputType == 'tags'">
        <span v-for="(tag, index) in text" :key="index">
          <Tag :name="tag" />
        </span>
      </span>
      <span v-else>
        <v-icon :class="iconClass">{{ icon }}</v-icon>
        <div style="display: inline-block" :class="textClass">
          {{ text }}{{ append }}
        </div>
      </span>
    </div>

    <span v-else-if="inputType == 'text'">
      <v-text-field
        :dark="dark"
        dense
        single-line
        :label="label"
        @input="
          (value) => {
            $emit('input', value);
          }
        "
        :rules="inputRules[rules]"
        :value="value"
      >
        <template v-if="icon" v-slot:prepend-inner
          ><v-icon :class="iconClass">{{ icon }}</v-icon></template
        >
        <template v-if="append" v-slot:append
          ><span>{{ append }}</span></template
        >
      </v-text-field>
    </span>
    <span v-else-if="inputType == 'date'">
      <v-menu
        max-width="290px"
        :close-on-content-click="false"
        v-model="dateDisplay"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            dense
            single-line
            :label="label"
            :value="formatDate(value)"
            v-on="on"
            :rules="inputRules[rules]"
          >
            <template v-if="icon" v-slot:prepend-inner
              ><v-icon :class="iconClass">{{ icon }}</v-icon></template
            >
            <template v-if="append" v-slot:append
              ><span>{{ append }}</span></template
            >
          </v-text-field>
        </template>
        <v-date-picker
          v-model="value"
          @input="
            (value) => {
              $emit('input', value);
              dateDisplay = false;
            }
          "
        ></v-date-picker>
      </v-menu>
    </span>
    <span v-else-if="inputType == 'textarea'">
      <v-textarea
        auto-grow
        dense
        :label="label"
        @input="
          (value) => {
            $emit('input', value);
          }
        "
        :rules="inputRules[rules]"
        :value="value"
      >
        <template v-if="icon" v-slot:prepend-inner
          ><v-icon :class="iconClass">{{ icon }}</v-icon></template
        >
        <template v-if="append" v-slot:append
          ><span>{{ append }}</span></template
        >
      </v-textarea>
    </span>
    <span v-else-if="inputType == 'time'">
      <v-menu
        max-width="290px"
        :close-on-content-click="false"
        v-model="timeDisplay"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            dense
            single-line
            :label="label"
            :value="value"
            v-on="on"
            :rules="inputRules[rules]"
          >
            <template v-if="icon" v-slot:prepend-inner
              ><v-icon :class="iconClass">{{ icon }}</v-icon></template
            >
            <template v-if="append" v-slot:append
              ><span>{{ append }}</span></template
            >
          </v-text-field>
        </template>
        <v-time-picker
          v-model="value"
          format="24hr"
          @input="
            (value) => {
              $emit('input', value);
            }
          "
        ></v-time-picker>
      </v-menu>
    </span>
    <span v-else-if="inputType == 'tags'">
      <Tag
        v-for="(tag, index) in Object.keys($store.state.tags)"
        :key="index"
        :name="tag"
        class="mt-2"
        :invertedColors="!value.includes(tag)"
        @click="tagClick(tag)"
      />
    </span>
    <span v-else-if="inputType == 'address'">
      <InputGeosearch
        :label="label"
        :icon="icon"
        styling="plain"
        :rules="rules"
        @input="
          (value) => {
            $emit('input', value);
          }
        "
        :inputValue="text"
      />
    </span>
    <span v-else-if="inputType == 'autocomplete'">
      <v-autocomplete
        dense
        single-line
        :items="items"
        :label="label"
        :rules="inputRules[rules]"
        @input="
          (value) => {
            $emit('input', value);
          }
        "
        :value="value"
      >
      </v-autocomplete>
    </span>
    <span v-else-if="inputType == 'regionAutocomplete'">
      <v-autocomplete
        dense
        single-line
        :items="$store.state.regions[Object.keys($store.state.regions)[0]]"
        item-text="displayValue"
        :label="label"
        :rules="inputRules[rules]"
        @input="
          (value) => {
            $emit('input', value);
          }
        "
        :value="value"
      >
      </v-autocomplete>
    </span>
  </div>
</template>

<script>
import inputRulesMixin from "@/mixins/inputRulesMixin.js";
import utilitiesMixin from "@/mixins/utilitiesMixin.js";
import Tag from "@/components/partials/Tag.vue";
import InputGeosearch from "@/components/partials/InputGeosearch";
export default {
  name: "EditableLabel",
  mixins: [utilitiesMixin, inputRulesMixin],
  components: { Tag, InputGeosearch },
  props: {
    editing: {
      type: Boolean,
      required: true,
    },
    text: {
      type: [String, Number, Array],
      default: "",
    },
    rules: {
      type: String,
      default: "None",
    },
    label: {
      type: String,
      default: "Label",
    },
    caption: {
      type: String,
      default: "",
    },
    textClass: {
      type: [String, Array],
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    append: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconClass: {
      type: String,
      default: "",
    },
    inputValue: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      dateDisplay: false,
      timeDisplay: false,
      value: this.formatValue(),
    };
  },
  methods: {
    formatValue() {
      if (this.inputValue != null) {
        return this.inputValue;
      } else if (this.inputType == "date") {
        return this.formatDateBack(this.text);
      } else {
        return this.text;
      }
    },
    tagClick: function (tag) {
      var index = this.value.indexOf(tag);
      if (index !== -1) {
        this.value.splice(index, 1);
      } else {
        this.value.push(tag);
      }
      this.$emit("input", this.value);
    },
  },
  watch: {
    text: function () {
      this.value = this.formatValue();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>