var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.caption && (_vm.inputType != 'address'))?_c('div',{staticClass:"caption grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.caption)+" ")]):_vm._e(),(!_vm.editing)?_c('div',[(_vm.inputType == 'tags')?_c('span',_vm._l((_vm.text),function(tag,index){return _c('span',{key:index},[_c('Tag',{attrs:{"name":tag}})],1)}),0):_c('span',[_c('v-icon',{class:_vm.iconClass},[_vm._v(_vm._s(_vm.icon))]),_c('div',{class:_vm.textClass,staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.text)+_vm._s(_vm.append)+" ")])],1)]):(_vm.inputType == 'text')?_c('span',[_c('v-text-field',{attrs:{"dark":_vm.dark,"dense":"","single-line":"","label":_vm.label,"rules":_vm.inputRules[_vm.rules],"value":_vm.value},on:{"input":function (value) {
          _vm.$emit('input', value);
        }},scopedSlots:_vm._u([(_vm.icon)?{key:"prepend-inner",fn:function(){return [_c('v-icon',{class:_vm.iconClass},[_vm._v(_vm._s(_vm.icon))])]},proxy:true}:null,(_vm.append)?{key:"append",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.append))])]},proxy:true}:null],null,true)})],1):(_vm.inputType == 'date')?_c('span',[_c('v-menu',{attrs:{"max-width":"290px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","single-line":"","label":_vm.label,"value":_vm.formatDate(_vm.value),"rules":_vm.inputRules[_vm.rules]},scopedSlots:_vm._u([(_vm.icon)?{key:"prepend-inner",fn:function(){return [_c('v-icon',{class:_vm.iconClass},[_vm._v(_vm._s(_vm.icon))])]},proxy:true}:null,(_vm.append)?{key:"append",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.append))])]},proxy:true}:null],null,true)},on))]}}]),model:{value:(_vm.dateDisplay),callback:function ($$v) {_vm.dateDisplay=$$v},expression:"dateDisplay"}},[_c('v-date-picker',{on:{"input":function (value) {
            _vm.$emit('input', value);
            _vm.dateDisplay = false;
          }},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1):(_vm.inputType == 'textarea')?_c('span',[_c('v-textarea',{attrs:{"auto-grow":"","dense":"","label":_vm.label,"rules":_vm.inputRules[_vm.rules],"value":_vm.value},on:{"input":function (value) {
          _vm.$emit('input', value);
        }},scopedSlots:_vm._u([(_vm.icon)?{key:"prepend-inner",fn:function(){return [_c('v-icon',{class:_vm.iconClass},[_vm._v(_vm._s(_vm.icon))])]},proxy:true}:null,(_vm.append)?{key:"append",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.append))])]},proxy:true}:null],null,true)})],1):(_vm.inputType == 'time')?_c('span',[_c('v-menu',{attrs:{"max-width":"290px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","single-line":"","label":_vm.label,"value":_vm.value,"rules":_vm.inputRules[_vm.rules]},scopedSlots:_vm._u([(_vm.icon)?{key:"prepend-inner",fn:function(){return [_c('v-icon',{class:_vm.iconClass},[_vm._v(_vm._s(_vm.icon))])]},proxy:true}:null,(_vm.append)?{key:"append",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.append))])]},proxy:true}:null],null,true)},on))]}}]),model:{value:(_vm.timeDisplay),callback:function ($$v) {_vm.timeDisplay=$$v},expression:"timeDisplay"}},[_c('v-time-picker',{attrs:{"format":"24hr"},on:{"input":function (value) {
            _vm.$emit('input', value);
          }},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1):(_vm.inputType == 'tags')?_c('span',_vm._l((Object.keys(_vm.$store.state.tags)),function(tag,index){return _c('Tag',{key:index,staticClass:"mt-2",attrs:{"name":tag,"invertedColors":!_vm.value.includes(tag)},on:{"click":function($event){return _vm.tagClick(tag)}}})}),1):(_vm.inputType == 'address')?_c('span',[_c('InputGeosearch',{attrs:{"label":_vm.label,"icon":_vm.icon,"styling":"plain","rules":_vm.rules,"inputValue":_vm.text},on:{"input":function (value) {
          _vm.$emit('input', value);
        }}})],1):(_vm.inputType == 'autocomplete')?_c('span',[_c('v-autocomplete',{attrs:{"dense":"","single-line":"","items":_vm.items,"label":_vm.label,"rules":_vm.inputRules[_vm.rules],"value":_vm.value},on:{"input":function (value) {
          _vm.$emit('input', value);
        }}})],1):(_vm.inputType == 'regionAutocomplete')?_c('span',[_c('v-autocomplete',{attrs:{"dense":"","single-line":"","items":_vm.$store.state.regions[Object.keys(_vm.$store.state.regions)[0]],"item-text":"displayValue","label":_vm.label,"rules":_vm.inputRules[_vm.rules],"value":_vm.value},on:{"input":function (value) {
          _vm.$emit('input', value);
        }}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }